@import '../../../styles/utils';

.header-cell {
  &-container {
    @extend %flex-space-between;
    max-width: 180px;
  }
  &-text {
    overflow: visible;
  }
  .icon {
    margin: 0;
  }
}
