%flex-align-center {
  display: flex;
  align-items: center;
}

%flex-align-flex-start {
  display: flex;
  align-items: flex-start;
}

%flex-align-flex-end {
  display: flex;
  align-items: flex-end;
}

%flex-space-between {
  display: flex;
  justify-content: space-between;
}

%flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

%flex-row {
  display: flex;
  flex-direction: row;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-space-between-row {
  @extend %flex-space-between;
  @extend %flex-row;
}
