@import 'src/styles/utils';

.consumers-table {
  tbody tr {
    cursor: pointer;
  }

  tbody tr:hover {
    background-color: $color-blue-1;
  }

  td {
    text-wrap: nowrap;
  }

  .consumer-key-column {
    max-width: 230px;
    min-width: 230px;
    width: 230px;
    overflow: hidden;
  }

  @include media('mobile-small') {
    .consumer-key-column {
      max-width: initial;
    }
  }

  .consumer-id-column {
    min-width: 310px;
    max-width: 310px;
    width: 310px;
  }

  .oauth-id-column {
    min-width: 230px;
    max-width: 230px;
    width: 230px;
  }

  .date-column {
    min-width: 125px;
    max-width: 125px;
    width: 125px;
  }

  .column-header-tooltip {
    .p-column-header-content {
      display: inline;
    }
  }

  .column-header-tooltip:after {
    content: ' \e924';
    font-family: primeicons, serif;
    font-weight: normal;
    color: $color-gray-6;
    vertical-align: middle;
  }

  .enabled-column {
    min-width: 80px;
    max-width: 80px;
    width: 80px;
  }

  .api-access-column {
    min-width: 200px;
  }
}
