/*******************************
        User Overrides
*******************************/
.ui.selection.dropdown{
   .menu > .item {
        min-height: 34px;
   }
}

.ui.dropdown > .text{
    word-break: break-word;
}

.ui.dropdown .menu{
    overflow: hidden;
}

.ui.dropdown .menu.right {
    right: 0px;
    left: auto;
}