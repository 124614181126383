@import 'src/styles/utils';

.info-popup {
  display: flex;
  align-items: flex-start;

  .info-popup-title {
    &.required {
      &:after {
        content: "*";
        margin-left: 5px;
        color: $color-red-6;
      }
    }
  }

  .icon {
    margin: 0px 0px 0px 5px;
    cursor: pointer;
  }
}