@import 'src/styles/utils';

.ui.consumer-trip-access-info-container {
  padding: 24px;
  @include media('desktop-small') {
    height: calc(100vh - 226px);
  }
  @include media('desktop') {
    height: calc(100vh - 226px);
  }

  .general-access-container {
    .general-access-title {
      margin-bottom: 8px;
      @extend %heading-4-bold;
    }
  }

  > .ui.grid {
    height: 100%;
    > .column {
      height: 100%;
      overflow-y: auto;
      padding-top: 0px;
      padding-bottom: 0px;

      &:not(:first-child) {
        padding-left: 24px;
      }

      &:first-child {
        padding-right: 24px;
      }
    }

    > .row {
      padding: 0px;
      > .column {
        margin: 0px;
        &.first-row {
          padding-bottom: 24px;
        }

        padding-top: 24px;
      }

      &.first-row {
        > .column {
          padding-bottom: 24px;
          padding-top: 0px;
        }
      }
    }
  }
}
