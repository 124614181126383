@import 'src/styles/utils';

.accounts-title-container {
  margin-bottom: 12px;
  row-gap: 6px;

  .side-buttons-container {
    align-items: center;
    margin: 0 -9px;

    @extend %flex-row;

    > div {
      margin: 0 9px;

      div.accounts-status-container,
      div.accounts-button-container {
        margin: 0 -4px;
        @extend %flex-row;

        > * {
          margin: 0 4px;
        }
      }
    }
  }
}

.accounts-content-container {
  height: calc(100vh - 272px);
  overflow-y: auto;
}

.accounts-enable-disable-buttons-container {
  margin-right: 10px;
}

.ui.checkbox.checkbox-input.acknowledgement-checkbox {
  font-size: 12px;
  line-height: 22px;

  label:before {
    transform: scale(0.8);
  }

  &::after {
    margin-left: 16px;
    margin-top: -22px;
    color: $monza;
    display: block;
    content: "*";
    font-size: 16px;
  }
}