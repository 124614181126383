@import 'src/styles/utils';

.named-radio-group {
  .named-radio-group-title {
    @extend %body-sm-bold;
    color: $color-gray-12;
    margin-bottom: 4px;
    opacity: 0.8;
  }
}
