@import 'src/styles/utils';

.header-with-search {
    margin-bottom: 4px;
}

.header-searchbox {
    .ui[class*="left icon"].input > input {
        padding-left: 2em !important;
    }
    
    .ui.input > input {
        padding-top: 3px;
        padding-bottom: 3px;
        width: 115px;
        font-size: 12px;
    }
}

.account-lookup-table {
    &.ui.table {
        th, td {
            padding: 12px;
        }
    }

    .checkbox-input {
        margin-right: 3px;
    }

    .column-account-name {
        .header-searchbox .ui.input > input {
            width: 155px;
        }
    }
}
