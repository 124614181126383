$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$header-height: 56px;
$page-padding: 24px;
$page-half-padding: $page-padding / 2;

$media-breakpoint-desktop-small-width: 1100px;
$media-breakpoint-width: 900px;
$media-breakpoint-mobile-small-width: 767px;
$media-breakpoint-mobile-min-width: 480px;
$media-breakpoint-tablet-landscape-width: 1024px;

$animationDuration: 0.3s;

$black: #000000;
$white: #ffffff;
$bahama-blue: #00629B;
$iron: #E1E3E6;
$rolling-stone: #76787A;
$monza: #DC002E;
$aluminium:  #AAAEB3;
$abbey:  #454647;
$osloGray: #909499;
$shark: #2C2D2E;
