/*******************************
         Site Overrides
*******************************/

.ui.table tbody tr td{
  &:first-child{
    border-bottom-left-radius: @borderRadius;
  }
  &:last-child{
    border-bottom-right-radius: @borderRadius;
  }
}