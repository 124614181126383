@import '../../../styles/utils';

.trip-modal-content-container {
  height: fit-content;
  margin-left: -18px;
  margin-right: -18px;
  
  .p-scrollpanel-wrapper {
    max-height: calc(100vh - 232px);
    border-radius: 4px;
    overflow-y: scroll;

    .p-scrollpanel-content {
      padding: 0 18px 0 0;
    }
    .p-blockui-container 
    {
      min-height: 100px;
    }
  }
}

.not-found-container {
  margin-top: 20px;
  color: $color-gray-6;

  .text {
    @extend %body-md;
  }
}

.not-found-title {
  font-size: 2em;
  font-weight: 700;
  color: $color-gray-6;
  margin-top: 20px;
  margin-bottom: 5px;
}