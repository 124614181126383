/*******************************
         Site Overrides
*******************************/

table.table.data-table {
  .label-group-cell .label {
    color: @color-gray-8;
  }

  tr.accordion-opened, tr.expandable-row, tr.active {
    .boolean-label-cell .label {
      background-color: @color-gray-4;
    }
  }
}

div.named-dropdown .ui.dropdown {
  .label, .label.active, .label:hover {
    background-color: @color-gray-3;
  }
}
