@import 'src/styles/utils';

.search-trips-form-container {
  .search-inputs-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    .search-input-element {
      margin-right: 2px;
      margin-bottom: 2px;
    }

    .search-input-element:last-child {
      margin-right: 0;
    }

    @media (max-width: 1280px) {
      .search-input-element {
        max-width: 160px;
      }
    }
  }
  .search-form-error {
    color: $color-red-6;
  }
}
