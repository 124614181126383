@import 'src/styles/utils';

.actions-container-mobile {
  color: $color-gray-12;
}

.ui.vertical.sidebar.actions-container-mobile-sidebar {
  .item::before {
    height: 0px;
  }

  .actions-container-mobile-sidebar-close {
    @extend %flex-justify-end;
  }

  .logout {
    @extend %body-md;
    cursor: pointer;
  }
}
