@import 'src/styles/utils';
.page-title {
  margin-bottom: 24px;
}

.page-title-container {
  flex-wrap: wrap;

  @extend %flex-space-between;

  .button.create-button {
    margin-right: 0;
    > i.icon {
      opacity: 1;
    }
  }
}

button.ui.button {
  @extend %action-sm;
}

.grid.page-body-container {
  > div.column:not(.row) {
    padding-right: 10px;
  }
}