@import 'src/styles/utils';

.actions-container-desktop {
  align-items: center;
  height: 100%;

  @extend %flex-row;

  .navigation-item {
    margin-right: 24px;
  }

  .user-logo-container {
    align-items: center;

    @extend %flex-row;

    .username {
      @extend %body-md-bold;
      cursor: pointer;
    }

    .user-logo {
      height: 24px;
      width: 24px;
      background-image: url('../../../assets/icons/user.svg');
      background-repeat: no-repeat;
      margin-right: 10px;
    }
  }
}
