/*******************************
         Site Overrides
*******************************/

.ui.checkbox input[disabled]:not([type=radio]):checked ~ label:before,
.ui.checkbox input[disabled]:not([type=radio]):indeterminate ~ label:before {
    border-color: @color-gray-5;
    background: @color-gray-5;
}

.ui.checkbox input[disabled]:not([type=radio]) ~ label:before {
    border-color: @color-black-transparent-16;
    background: @color-black-transparent-07;
}

.ui.checkbox input[disabled][type=radio] ~ label:before {
    border-color: @color-black-transparent-40;
    background: @color-black-transparent-07;
}

.ui.radio.checkbox input[disabled][type=radio] ~ label:after {
    background-color: @color-black-transparent-40;
}
