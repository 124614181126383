@import '../../../styles/utils';

.code-content-wrapper {
  background-color: $color-gray-3;
  position: relative;
  padding: 12px;
  pre {
    margin: 0px;
    font-size: 12px;
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 16px;
  }
  .code-content-controls {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
