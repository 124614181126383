@import 'src/styles/utils/variables';

@function translate-media-condition($c) {
  $condMap: (
    'mobile-small': '(max-width: #{$media-breakpoint-mobile-small-width})',
    'mobile': '(max-width: #{$media-breakpoint-width})',
    'desktop-small': '(min-width: #{$media-breakpoint-width + 1}) and (max-width: #{$media-breakpoint-desktop-small-width})',
    'desktop': '(min-width: #{$media-breakpoint-width + 1})'
  );
  @return map-get($condMap, $c);
}

@mixin media($arg) {
  @media #{translate-media-condition($arg)} {
    @content;
  }
}
