@import '../../../styles/utils';

.label-group-cell {
  .label-group-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    margin: -4px;
    .label {
      margin: 4px;
      padding: 4px 12px;
      @extend %body-sm-bold;
    }
  }
}
