@import 'src/styles/utils/variables';

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/Proxima\ Nova\ Black.otf') format('opentype');
  font-style: normal;
  font-weight: $font-weight-black;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/Proxima Nova Bold.otf') format('opentype');
  font-style: normal;
  font-weight: $font-weight-bold;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/Proxima Nova Extrabold.otf') format('opentype');
  font-style: normal;
  font-weight: $font-weight-extrabold;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/Proxima Nova Regular.otf') format('opentype');
  font-style: normal;
  font-weight: $font-weight-regular;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/src/assets/fonts/Proxima Nova Thin.otf') format('opentype');
  font-style: normal;
  font-weight: $font-weight-thin;
}
