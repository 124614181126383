@import 'src/styles/utils';

.container-datePickerWrapper {
  .container-cronLabel {
    &.field {
      &>label {
        margin-bottom: 0px;
      }

      margin-bottom: 8px;
    }
  }

  .react-datepicker {
    min-width: 298px;
  }

  &.disabled__time-select .react-datepicker__time-container {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container,
    .react-datepicker-time__input {
      input {
        width: 100%;
        height: 32px;
        font-size: 14px;
        margin: 0em;
        max-width: 100%;
        flex: 1 0 auto;
        outline: none;
        text-align: left;
        line-height: 16px;
        padding: 8px 16px;
        background: $color-gray-1;
        border: 1px solid $color-gray-6;
        color: $color-black-2;
        border-radius: 6px;
        transition: box-shadow 0.1s ease, border-color 0.1s ease;
        box-shadow: none;

        &:disabled {
          opacity: 0.45;
        }

        &:focus {
          border-color: $color-blue-3_1;
          background: $color-gray-1;
          color: $color-black-3;
          box-shadow: none;
        }
      }

      .ui {
        &.input {
          &.icon {
            input {
              padding-right: 30px !important;
            }
          }
        }
      }

      &.react-datepicker-time__input {
        input {
          height: 24px;
          padding: 4px 6px;
        }
      }
    }
  }

  &.error-datapicker-field {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          background-color: $color-red-1_1;
          border-color: $color-red-1_2;
          color: $color-red-4_1;
        }
      }
    }
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    top: 6px;
  }
}