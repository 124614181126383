@import 'src/styles/utils';

.consumer-info-header-container {
  .message.message-alert {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.consumer-info-navigation-container {
  @extend %flex-row;

  border-bottom: 1px solid $color-gray-5;
  margin: 24px 0;
}
