@import 'src/styles/utils';

.consumer-main-info-container {
  padding: 10px;

  h4 {
    margin: 24px 0 12px;
  }

  h5 {
    margin: 12px 0;
  }

  .consumer-main-info-row {
    margin-bottom: 24px;
  }

  .grid.consumer-main-info-grid-row,
  .grid > .row.consumer-main-info-grid-row {
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .consumer-contacts-title {
    color: $color-gray-12;
    @extend %heading-4-bold;
  }

  .consumer-emails-title,
  .consumer-contacts-email-entry,
  .consumer-info-access-entry,
  .create-consumer-api-content {
    opacity: 0.8;
  }

  .consumer-contacts-email-main {
    font-weight: $font-weight-bold;
    .label {
      margin-left: 4px;
      @extend %body-sm;
      line-height: 7px;
      background-color: $color-gray-3;
      color: $color-gray-12;
    }
  }
}
