@import 'src/styles/utils';

.named-input {
  .named-input-title {
    @extend %body-sm-bold;
    color: $color-gray-12;
    margin-bottom: 4px;
    opacity: 0.8;
  }

  .ui.input {
    @extend %body-sm;
  }
}

.named-input-error-msg {
  padding: 4px 8px 0 8px;
}
