@import '../../styles/utils';

.label-group-container {
  .label {
    margin: 4px 8px 4px 0;
    padding: 4px 12px;
    @extend %body-sm;
  }
}

.label-group-prime-container {
  display: flex;
  .label-tag {
    background-color: $color-gray-3;
    color: $color-gray-8;
    margin-right: 2px;
  }
  .label-tag:last-child {
    margin-right: 0;
  }
}
