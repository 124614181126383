@import 'src/styles/utils';

@keyframes delay-overflow {
  from {
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}

@keyframes delay-min-height {
  from {
    min-height: 0px;
  }
  to {
    min-height: 150px;
  }
}

.advanced-settings-navigation-container {
  @extend %flex-row;
  align-items: center;
  padding-left: 4px;

  .advanced-settings-navigation-entry {
    flex-shrink: 0;
  }

  .advanced-settings-full-view-button {
    white-space: nowrap;
    margin: 0;
    margin-left: 4px;
  }

  .advanced-settings-dropdown-icon {
    &.advanced-settings-dropdown-icon-root {
      margin-right: 14px;
    }
  }

  .advanced-settings-navigation-item-row {
    @extend %flex-row;
    flex-shrink: 1;

    .advanced-settings-navigation-item {
      color: $color-blue-4;
      padding: 8px 12px;
      border-radius: 6px;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 4px;
      }
      &:hover {
        background-color: $color-blue-2;
      }
    }
  }
}

.advanced-settings-container {
  padding: 0 24px;

  .advanced-settings-dropdown-icon {
    display: inline-block;
    width: 12px;
    cursor: pointer;
    position: relative;
    margin-right: 0;
  }

  .advanced-settings-api-response-checkbox {
    margin-right: 14px;
    label {
      &:after {
        @extend %body-sm;
      }
      @extend %heading-4-bold;
    }
  }

  .advanced-settings-divider {
    margin: 9px -24px 0px;
  }

  .advanced-settings-dropdown-icon-hidden {
    visibility: hidden;
  }
}
