%elevation-style-1 {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

%elevation-style-2 {
  box-shadow: 0px 1.25px 4px rgba(0, 0, 0, 0.25);
}

%elevation-style-3 {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);
}

%elevation-style-4 {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.24);
}

%elevation-style-5 {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}

%elevation-style-6 {
  box-shadow: 0px 3.5px 14px rgba(0, 0, 0, 0.2);
}

%elevation-style-7 {
  box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.17);
}

%elevation-style-8 {
  box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.2);
}
