@import 'src/styles/utils';

.advanced-settings-rows-container {
  overflow-y: auto;
  overflow-x: hidden;
  transition: max-height 0.5s ease-out;
  min-height: 150px;

  &.page-embedded {
    height: calc(100vh - 408px);
    margin-bottom: -56px;
    padding-bottom: 40px;
    max-height: calc(100vh - 408px);
    margin-right: -25px;
    scrollbar-width: thin;

    .advanced-settings-indent-container {
      &.root-indent {
        margin-left: 65px;
      }
    }

    .advanced-settings-dropdown-icon {
      &.advanced-settings-dropdown-icon-root {
        margin-right: 40px;
      }
    }
  }

  .advanced-settings-dropdown-icon {
    &.icon-right {
      top: 2px;
    }

    &.advanced-settings-dropdown-icon-root {
      margin-right: 16px;
    }
  }

  &.shown {
    animation-name: delay-min-height;
    animation-duration: 1s;
    animation-iteration-count: 1;
  }

  &,
  &.advanced-settings-rows-container-readonly {
    &.hidden {
      max-height: 0;
      overflow: none;
      min-height: 0;
      padding: 0;
    }
  }

  &-content {
    position: relative;

    &:after {
      content: '';
      background: $color-gray-6;
      position: absolute;
      height: 100%;
      width: 1px;
      top: 0;
      left: 38px;
    }
  }

  @include media('mobile') {
    height: 300px;
  }

  &.advanced-settings-rows-container-readonly {
    height: calc(100vh - 395px);
    max-height: calc(100vh - 395px);
    margin-bottom: 0px;
    padding-bottom: 0px;

    &::-webkit-scrollbar-track {
      margin-bottom: 0px;
    }

    @include media('mobile') {
      height: 300px;
    }
  }

  .advanced-settings-indent-container {
    border-left: 1px solid $color-gray-6;
    margin-left: 24px;
    transition: max-height 0.5s ease-out;

    .shown {
      animation-name: delay-overflow;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }

    &.hidden {
      overflow: hidden;
    }

    &.root-indent {
      margin-left: 40px;
    }
  }

  .trip-access-advanced-settings-row {
    padding: 8px 4px;

    .checkbox {
      @extend %body-sm;
    }
  }
}