@import 'src/styles/utils';

.not-found-page-container {
  padding-top: 16px;

  .not-found-page-container-title {
    display: flex;
    flex-direction: row;

    .icon {
      margin-top: 2px;
    }

    h2 {
      margin: 0;
      @extend %heading-2-bold;
    }
  }

  .not-found-page-container-content {
    margin-top: 8px;
    color: $color-gray-10;
  }

  .ui.not-found-page-container-link-button {
    @extend %body-md;
    margin-top: 16px;
    padding: 12px 16px;
  }
}
