@import 'src/styles/utils';

.navigation-item {
  @extend %flex-align-center;

  .navigation-item-link {
    color: $color-gray-10;
    padding: 16px;
    @extend %action-md;

    &.navigation-item-link-active {
      color: $color-blue-4;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3px;
        background: $color-blue-4;
      }
    }
  }

  .navigation-item-link-tab {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .navigation-item-link-horizontal {
    color: $color-gray-6;
    @extend %action-md;

    &.navigation-item-link-active {
      position: relative;
      color: $color-gray-10;
      &::after {
        content: '';
        position: absolute;
        left: -16px;
        right: 0;
        height: 100%;
        width: 3px;
        background: $color-blue-4;
      }
    }
  }
}
