@import 'utils';

body,
.page-container {
  margin: 0;
  padding: 0;
  @extend %body-md;
  color: $color-gray-12;
  background-color: $color-gray-2 !important;
  height: 100%;
}

h1 {
  @extend %heading-1;
}

h2 {
  @extend %heading-2;
}

h3 {
  @extend %heading-3;
}

h4 {
  @extend %heading-4;
}

.body-container {
  @include media('desktop-small') {
    height: calc(100vh - 76px);
  }

  @include media('desktop') {
    height: calc(100vh - 76px);
  }

  padding: $page-padding $page-padding $page-half-padding;
  background-color: $color-gray-2;
  z-index: 0;
  position: relative;
  display: block;
}

.error-message {
  @extend %body-sm;
  color: $color-red-3;
}

.ui.popup.dropdown-popup {
  padding-left: 0px;
  padding-right: 0px;

  .dropdown-popup-content {
    cursor: pointer;
    padding: 0 50px 0 16px;
    @extend %body-sm;
    color: $color-gray-10;
  }
}

.ui.checkbox.checkbox-input {
  @extend %body-md;
  color: $color-gray-12;

  label:before {
    top: 3px;
  }
}

.ui.breadcrumb {
  @extend %body-md;
}

.Toastify__toast-container {
  width: 395px;
  max-width: 100%;
  top: 74px;
  z-index: 1004;

  .custom-toast.Toastify__toast {
    min-height: auto;
    padding: 8px 16px;
    border-radius: 6px;
    font-family: inherit;

    button {
      align-self: auto;
      margin-left: 5px;
    }

    .custom-toast-body {
      padding: 0;
      color: $color-gray-10;
      @extend %body-md;
    }

    &--success {
      background-color: $color-green-6;
      border: 1px solid $color-green-2;

      .Toastify__toast-icon svg {
        fill: $color-green-2;
      }
    }

    &--error {
      background-color: $color-red-1;
      border: 1px solid $color-red-3;

      .Toastify__toast-icon svg {
        fill: $color-red-3;
      }
    }
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.dropdown-datepicker-range {
  &.ui {
    &.grid {
      &>.row {
        &>.column {
          &:not(:last-child) {
            padding-right: 5px;
          }

          &:not(:first-child) {
            padding-left: 5px;
          }
        }
      }
    }
  }
}

.link {
  color: $bahama-blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}