@import 'src/styles/utils';

.header-container {
  background-color: $color-gray-1;
  height: $header-height;
  padding: 0 24px;
  align-items: center;
  position: relative;
  z-index: 1;

  @extend %flex-space-between-row;
  @extend %elevation-style-1;

  .logo {
    height: 30px;
    width: 193px;
    background-image: url('../../assets/icons/logo.svg');
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 767px) {
  .header-container {
    padding: 0 10px;
  }
}
