@import 'src/styles/utils';

.events-form-container {
  &.ui.grid {
    &>.row {
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      &>.column {
        padding-left: 0.25rem;
        padding-right: 0.25rem;

        .ui.button {
          margin-top: 20px;
        }
      }
    }
  }
}