@import 'src/styles/utils';

.general-access-container {
  .general-access-header {
    margin: 0 0 8px 0;
    opacity: 0.8;
    @extend %body-sm-bold;
  }
}

.trip-access-container {
  margin-bottom: 10px;
  @extend %flex-space-between;

  .radio-group-container {
    margin-bottom: 38px;
  }

  .trip-access-label {
    @extend %heading-4-bold;
  }
}

.trips-scope-radio-row {
  .ui.grid > .row {
    padding-top: 0px;
  }
}

.trip-access-preset-dropdown {
  margin-top: 16px;
}

.trip-access-preset-visualization {
  margin-top: 8px;
  @extend %body-sm;

  &.preset-hover-preview {
    margin-top: 0;
  }

  .preset-object-label {
    @extend %body-sm-bold;
  }
}
