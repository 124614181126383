/*******************************
         Site Overrides
*******************************/


button.ui.button.icon-square-button {
    padding: 8px;
    > i.icon {
      margin: 0 !important;
    }
}

button.ui.button.icon-square-button-small {
    padding: 1px;
    > i.icon {
      margin: 0 !important;
    }
}