@import 'src/styles/utils';

.trip-modal {
  width: 900px;
  max-height: calc(100vh - 64px);

  .p-dialog-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }

  .p-tabview-panel {
    padding: 0rem 0 1.25rem 0;
  }

  .p-progress-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);

    z-index: 10;
  }
}
