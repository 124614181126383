.horizontal-scroll-root {
  display: flex;
  overflow: hidden;
  align-items: baseline;

  .scroll-button {
    &.hidden {
      visibility: hidden;
    }
  }

  .horizontal-scroll-content {
    display: flex;
    overflow-x: scroll;
    flex: 1 1 auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
