@import 'src/styles/utils';

.accounts-form-container.ui.segment {
  .customers-logic-row h4 {
    @extend %heading-4-bold;
  }

  .accounts-form-inclusive-message {
    padding-top: 16px;
    @extend %body-sm;
    color: $color-gray-8;
  }
}

.modal-header-wrapper {
  display: flex;
  align-items: center;
}
.modal-header-exclamation-icon {
  color: #c8102e;
  font-size: 35px;
  margin-right: 15px;
}
