@import 'src/styles/utils';

.footer-container {
  @extend %body-xs;
  color: $color-gray-7;
  @extend %flex-column;
  align-items: flex-start;
  padding: 0px $page-padding 6px;

  @include media('desktop') {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-link-text{
    color: $color-gray-7;
    &:hover{
      color: $color-gray-8;
    }
    &:not(:first-child){
      margin-left: 14px;
    }
  }
}
