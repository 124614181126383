@import 'src/styles/utils';

.general-access-container {
  &-info {
    .boolean-label-row:not(:last-child) {
      margin-bottom: 16px;
    }

    .data-source-text-block {
      color: $color-gray-8;
      min-width: 200px;
      @extend %body-md;
    }
  }
}

.data-source-view-list {
  &.ui {
    &.list {
      &>.item {
        padding: 0px;
        display: flex;

        .data-source-view-checkbox-container {
          width: 150px;
          padding: 0px;
        }

        .data-source-view-providers-list {
          &.ui {
            &.list {
              @extend %flex-align-center;
              &>.item {
                margin-left: 0px;
                margin-right: 12px;
                @extend %flex-align-center;

                .data-source-view-provider-not-set-popup {
                  margin-left: 6px;
                  padding: 0px;
                  display: block;
                  width: auto;
                  height: auto;
                }
              }
            }
          }
        }

        .data-source-more-control {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}