.consumers-title-container {
  padding-bottom: 24px;
}

.consumers-options-button.ui.button {
  margin-left: 12px;
  height: 32px;
  width: 32px;
  padding: 0;
  svg {
    vertical-align: middle;
  }
}

.consumers-content-container {
  height: calc(100vh - 212px);
  overflow-y: auto;
}

.consumers-title {
  margin-bottom: 12px;
}
