$color-blue-1: #EFF4F6;
$color-blue-2: #CEDBEE;
$color-blue-3: #9CB7DD;
$color-blue-3_1: #85B7D9;
$color-blue-4: #5A88C6;
$color-blue-5: #3763A0;
$color-blue-6: #25426A;
$color-blue-7: #0B2D5A;
$color-blue-8: #041E5B;
$color-blue-9: #031030;
$color-blue-10: #00629B;
$color-blue-11: #E5F0FF;
$color-blue-12: #004166;

$color-gray-1: #FFFFFF;
$color-gray-2: #F9F9F9;
$color-gray-3: #F2F3F5;
$color-gray-4: #E1E3E6;
$color-gray-5: #C4C8CC;
$color-gray-6: #AAAEB3;
$color-gray-7: #909499;
$color-gray-8: #76787A;
$color-gray-9: #5D5F61;
$color-gray-10: #454647;
$color-gray-11: #2C2D2E;
$color-gray-12: #19191A;
$color-gray-13: #0A0A0A;
$color-gray-15: #DEDEDE;

$color-red-1: #FFDDE1;
$color-red-1_1: #FFF6F6;
$color-red-1_2: #E0B4B4;
$color-red-2: #F07285;
$color-red-3: #DC002E;
$color-red-4: #AA1228;
$color-red-4_1: #9F3A38;
$color-red-5: #720C1B;
$color-red-6: #C8102E;

$color-green-1: #9CCF57;
$color-green-2: #8DC73F;
$color-green-3: #6A982C;
$color-green-4: #47651E;
$color-green-5: #2C4B00;
$color-green-6: #EFFAE1;

$color-black-1: #000;
$color-black-2: rgba(0, 0, 0, 0.87);
$color-black-3: rgba(0, 0, 0, 0.8);

$color-background-white-transparent: rgba(255, 255, 255, 0.9);