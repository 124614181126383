@import 'src/styles/utils';

.textarea {
    background: $color-gray-1;
    border: 1px solid $color-gray-4;
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: none;
    line-height: 18px;
    font-size: 14px;
    overflow-y: auto;
    height: 100px;

    &-focused {
        color: $color-black-3;
        border-color: $color-blue-3_1;
    }

    &-error {
        border-color: $color-red-1_2;
    }
  }

.custom-highlight {
    background-color: $color-red-1_2;
    font-size: 14px;
    line-height: 18px;
    border-radius: 500rem;
    padding-left: 4px;
    padding-right: 4px;
}

.textarea-error-msg {
    padding: 4px 8px 0 8px;
}