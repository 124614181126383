@import 'src/styles/utils';

.p-inputtext:focus,
.p-button:focus {
  box-shadow: none;
}

.p-datatable table {
  border: 1px solid #e5e7eb;
}

.p-tooltip {
  max-width: 210px;
}

.p-progress-spinner {
  width: 50px;
  height: 50px;
  .p-progress-spinner-circle {
    stroke: $color-gray-10 !important;
  }
}
