.p-menu {
  border-radius: 4px;

  .p-menuitem:nth-of-type(2) > .p-menuitem-content {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .p-menuitem:last-of-type > .p-menuitem-content {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .p-submenu-header {
    display: none;
  }
}
