@import 'src/styles/utils';

.users-title-container {
  padding-bottom: 14px;

  .ui.input {
    width: 362px;
  }
}

.users-content-container {
  height: calc(100vh - 214px);
  overflow-y: auto;
  .grid > .row {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
