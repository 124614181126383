@import 'src/styles/utils/variables';

%body-sm {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 14px;
  line-height: 16px;
}

%body-sm-bold {
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 14px;
  line-height: 16px;
}

%body-xs {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 10px;
  line-height: 14px;
}

%body-xs-bold {
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 10px;
  line-height: 14px;
}

%body-md {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 16px;
  line-height: 24px;
}

%body-md-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 24px;
}

%body-lg {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 18px;
  line-height: 28px;
}

%body-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 28px;
}

%action-lg {
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 18px;
  line-height: 28px;
}

%action-md {
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 16px;
  line-height: 24px;
}

%action-sm {
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: 13px;
  line-height: 16px;
}

%heading-1 {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 32px;
  line-height: 38px;
}

%heading-1-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 32px;
  line-height: 38px;
}

%heading-2 {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 24px;
  line-height: 30px;
}

%heading-2-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 24px;
  line-height: 30px;
}

%heading-3 {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 20px;
  line-height: 30px;
}

%heading-3-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 20px;
  line-height: 30px;
}

%heading-4 {
  font-style: normal;
  font-weight: $font-weight-regular;
  font-size: 16px;
  line-height: 18px;
}

%heading-4-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 18px;
}

%heading-5-bold {
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: 27px;
  line-height: 30px;
}

%heading-6 {
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: 14px;
  line-height: 24px;
}
