@import 'src/styles/utils';

.faq-container {
  .page-form-container {
    padding: 24px;
    @extend %body-md;

    h3 {
      margin-bottom: 24px;
      @extend %heading-3-bold;
    }

    h4 {
      margin-bottom: 16px;
      @extend %heading-4-bold;
    }

    a {
      cursor: pointer;
    }

    .message {
      display: flex;
      margin-bottom: 16px;
      @extend %body-sm;

      .header {
        margin-bottom: 4px;
        @extend %body-md;
      }

      .icon {
        margin-right: 9px;
      }
    }

    .faq-table {
      white-space: normal;
      border-radius: 8px;
      border: 1px solid $color-gray-4;

      thead th {
        color: $color-gray-12;
        border-bottom: 1px solid $color-gray-4;
      }
    }

    .sidebar {
      padding-top: 3px;
      border-left: 1px solid $color-gray-5;

      a {
        color: $color-blue-10;
        display: block;
      }

      &-item-header {
        color: $color-gray-10;
        padding-left: 16px;
        margin: 8px 0;
        @extend %body-md-bold;
      }

      &-item-list {
        padding: 3px 0;
        margin-left: 24px;
        border-left: 1px solid $color-gray-5;

        .li {
          padding-left: 16px;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }

      a div {
        border-left: 3px solid transparent;
        margin-left: -1px;
      }

      a.active-link div {
        color: $color-blue-12;
        border-left: 3px solid $color-blue-12;
      }

      .sidebar-item-list {
        counter-reset: section;

        & .li:before {
          content: counter(section) ".";
          counter-increment: section;
          display: inline;
          margin-right: 5px;
        }
      }
    }

    .faq-column {
      height: calc(100vh - 270px);

      a {
        color: $color-blue-10;
      }

      overflow-y: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .faq-content {

      &-section {
        counter-reset: section;
        padding-bottom: 16px;

        &:last-child {
          padding-bottom: calc(100vh - 420px);
        }

        .active h3,
        .active h4 {
          color: $color-blue-12;
        }

        .faq-content-list {
          margin: 0;
          padding-inline-start: 18px;
        }

        .faq-content-list-nested {
          list-style-type: lower-alpha;
        }


        &>div {
          padding-bottom: 6px;
          &+div {
            margin-top: 18px;
          }
          &:last-child{
            padding-bottom: 0px;
          }
        }

        &-item {
          h4:before {
            content: counter(section) ".";
            counter-increment: section;
            display: inline;
            margin-right: 5px;
          }

          &-row {
            margin-top: 0;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}