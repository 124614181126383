.new-accounts-grid {
  padding: 0 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: auto;
  max-height: calc(100vh - 250px);

  span {
    padding-right: 1px;
  }
}

.new-accounts-grid::after {
  content: '';
  flex: auto;
}
