@import '../../styles/utils';

table.table {
  &.data-table {
    thead th {
      @extend %body-sm-bold;
    }

    tbody {
      tr.disabled-status-row {
        color: $color-gray-8;
      }

      .icon {
        color: $color-gray-6;
      }
    }
  }

  td.overflow-cell {
    text-overflow: ellipsis !important;
  }

  tr.expandable-row-header {
    @extend %action-md;

    td {
      padding: 8px 0.78571429em;
    }
  }

  th.small-header-cell-buttons {
    width: 5%;
  }

  &.ui.fixed {
    overflow: scroll;

    th,
    td {
      text-overflow: clip;
    }
  }

  &.ui.table.accordion-table {
    tbody {
      tr.accordion-opened {
        background-color: $color-gray-2;
      }

      .expandable-row,
      .expandable-row-header {
        background-color: $color-gray-3;

        &.expandable-row-header {
          .small-header-cell-buttons {
            @extend %body-sm-bold;
            padding-left: 16px;
            padding-right: 16px;
          }
        }

        &.active {
          background-color: $color-gray-4 !important;
        }
      }

      i.icon.angle {
        color: $color-gray-10;
      }
    }
  }
}

.table-sticky-container {
  border-radius: 0.28rem;
  border-top: 1px solid $color-gray-15;

  .table.data-table {
    border-top: 0;

    thead {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      background-color: inherit;
      z-index: 1;
    }
  }
}

@include media('mobile') {
  .table.data-table {

    tbody tr,
    td,
    thead th {
      padding: 0.5em 0.3em;
    }

    &.ui.table.accordion-table {
      tbody {

        .expandable-row,
        .expandable-row-header {
          &.expandable-row-header {
            td {
              padding-left: 0.3em;
              padding-right: 0.3em;
            }
          }
        }
      }
    }
  }
}

@include media('mobile-small') {
  .table.data-table {
    tbody tr {
      position: relative;

      td.cell-dropdown {
        position: absolute;
        top: 1em;
        right: 0.5em;
      }
    }
  }
}
