@import 'src/styles/utils';

.title-container {
  margin-bottom: 4px;
  display: flex;
  gap: 8px;
  align-items: flex-end;

  .named-highlights-title {
    @extend %body-sm-bold;
    color: $color-gray-12;
    opacity: 0.8;
  }

  .loupe {
    background: transparent;
    i {
      color: #5a88c6;
    }
  }
}
