@import 'src/styles/utils';

.create-consumer-emails-title-container {
  @extend %flex-row;
  align-items: center;

  .create-consumer-emails-title {
    color: $color-gray-12;
    @extend %heading-4-bold;
  }

  .create-consumer-emails-add-button {
    margin-left: 24px;
    height: 34px;
    width: 34px;
  }
}

.ui.create-consumer-emails-entry-divider {
  margin-top: 16px;
  margin-bottom: 0px;
}

.create-consumer-emails-entry {
  padding-top: 8px;

  .consumer-emails-is-primary-column {
    padding-top: 40px;
  }

  .create-consumer-emails-entry-email-container {
    @extend %flex-row;

    .create-consumer-emails-input {
      flex-grow: 1;
    }

    .ui.create-consumer-emails-delete-button {
      align-self: flex-end;
      height: 34px;
      width: 34px;
      padding: 0;
      padding-left: 10px;
      margin: 0;
      margin-left: 24px;

      &-validation-errors {
        margin-bottom: 20px;
      }
    }
  }

  .create-consumer-emails-entry-row {
    padding-top: 16px;
  }

  .create-consumer-emails-checkbox {
    margin-bottom: 4px;
    label:before {
      top: 3px;
    }
  }
}
