/*******************************
        Site Overrides
*******************************/

.ui.message {
  box-shadow: none;

  &.green {
    color: @color-gray-1;
    background-color: @color-green-2;
  }

  &.red {
    background-color: @color-red-1;
    color: @color-red-3;
  }

  &.blue {
    padding: 8px 16px;
    background-color: @color-blue-11;
    color:@color-blue-10;
    .content {
      color:@color-gray-10;
    }
    .header {
      color:@color-gray-12;
    }
    i.icon{
      align-self: flex-start;
    }
  }
}
