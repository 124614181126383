@import 'src/styles/utils';

.search-trips-table-container {
  font-size: 12px;
  font-family: 'Proxima Nova', sans-serif;

  .search-trips-table-row {
    line-height: 20px;
  }

  .p-datatable-thead {
    line-height: 16px;
    font-weight: bolder;
    white-space: nowrap;
  }

  .no-data-row {
    display: flex;
    justify-content: center;
    align-items: center;
    .ui {
      color: $color-gray-6;
    }
  }

  .travelers-email-content {
    max-width: 200px;
  }

  .no-word-break {
    white-space: nowrap;
  }

  .word-break {
    word-break: break-all;
  }
}
