@import 'src/styles/utils';

.named-dropdown {
  .named-dropdown-title {
    @extend %body-sm-bold;
    color: $color-gray-12;
    margin-bottom: 4px;
    opacity: 0.8;

    &.required {
      &:after {
        content: "*";
        margin-left: 5px;
        color: $color-red-6;
      }
    }
  }

  .dropdown {
    position: relative;

    .label {
      @extend %body-sm;
    }

    .text+.icon {
      cursor: pointer;
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 0.1s ease;
    }
  }
}