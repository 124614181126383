@import 'src/styles/utils';

.general-access-container {
  &-edit {
    .radio-group-container {
      margin-bottom: 50px;
    }

    .named-radio-group .grid .row {
      width: auto !important;
    }
  }
}

.trip-access-advanced-settings-container {
  @extend %flex-column;
  height: 100%;
  justify-content: space-between;

  .page-form-footer {
    width: calc(100% - 20px);
    background-color: $color-background-white-transparent;
    z-index: 2;
  }
}

.data-source-form-list {
  &.ui {
    &.accordion {

      .data-source-form-type-container {
        @extend %flex-align-center;
        padding: 0px;

        .data-source-form-checkbox-container {
          width: 150px;
        }
      }

      .data-source-form-providers-container {
        padding: 0.5em 0 0.5em 2em !important;
      }
    }
  }
}