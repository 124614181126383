@import 'src/styles/utils';

.auth-container {
  display: flex;
  min-height: 100vh;

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 85px;
    background: $white;

    @media (max-width: $media-breakpoint-tablet-landscape-width) {
      padding: 16px 88px;
      width: 100%;
      align-items: center;
    }

    @media (max-width: $media-breakpoint-mobile-min-width) {
      padding: 24px;
    }

    .container-emailSignIn {
      font-size: 16px;

      button {
        font-size: 16px;
        font-weight: 400;
        padding: 12px;
      }

      .formFieldLabel {
        display: table;
        font-size: 14px;
        font-weight: 400;
      }
    }
  
    .container-defaultSignIn {
      padding-top: 34px;
      width: 350px;
  
      .separator {
        margin-top: 16px;
      }

      .button-federatedSignIn {
        font-size: 16px;
        font-weight: 400;
        padding: 12px;
      }

      .button-emailSignIn {
        font-size: 14px;
        white-space: nowrap;
        border: 0px;
        background: none;
        padding: 0px;
        font-family: Proxima Nova, sans-serif;
        font-weight: normal;
        color: $black;

        &:hover,
        &:focus{
          background-color: transparent;
        }
      }
    }

    .container-logoWrapper {
      width: 350px;
    }

    .form {
      width: 350px;

      h2 {
        font-size: 27px;
        line-height: 30px;
        color: $shark;
      }

      .field {
        &+.button {
          margin-top: 24px;
        }
      }
    }

    .form-changePassword {
      font-size: 16px;

      h2 {
        font-weight: 700;
      }
      
      label {
        font-weight: 400;
      }

      button {
        font-size: 16px;
        font-weight: 400;
        padding: 12px;
      }

      .formFieldLabel {
        display: table;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .center-content {
      text-align: center;
      margin-top: 24px;
    }

    .separator {
      position: relative;
      margin: 24px 0px;
      font-weight: $font-weight-bold;
      color: $rolling-stone;
      text-align: center;

      @media (max-height: 720px) {
        margin: 16px 0;
      }

      .ui {
        &.button {
          margin: 0px;
        }
      }

      &>* {
        cursor: pointer;
        opacity: 1;
        transition: $animationDuration;

        &:hover {
          opacity: 0.5;
        }
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 35%;
        height: 1px;
        background: $iron;
      }

      &:before {
        right: 0;
      }

      &:after {
        left: 0;
      }
    }

    .link-text {
      display: inline-block;
      font-weight: $font-weight-bold;
      color: $bahama-blue;
      margin: 0px;
    }

    .formError {
      padding-top: 0;
    }

    .form-container-footer {
      padding-top: 16px;
    }
  }

  .banner {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url("../../assets/banner.jpg");
    background-size: cover;
  }
}

.banner {
  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1600px) {
    background-position: 10%;
  }
}

.form-container-footer {
  font-size: 14px;
  line-height: 16px;
  color: $aluminium;
  width: 330px;

  .link.link {
    margin-left: 4px;
  }
}

.error-message {
  padding-bottom: 16px;
  word-break: break-word;
}