@import 'src/styles/utils';

.events-trips-table-container {
  &.ui.table {
    thead {
      tr {
        th {
          font-size: 12px;
          line-height: 1.25;
          padding: 6px;
          white-space: nowrap;

          &:first-child {
            padding-left: 1rem;
          }

          &:last-child {
            padding-right: 1rem;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 12px;
          line-height: 1.25;
          padding: 6px;

          &:first-child {
            padding-left: 1rem;
          }

          &:last-child {
            padding-right: 1rem;
          }

          &.trips-table-send-control-cell {
            text-align: right;

            .ui {
              &.button {
                &:not(.icon) {
                  margin-right: 0px;
                  padding: 4px 8px;
                  font-weight: normal;
                  white-space: nowrap;

                  &>.icon {
                    &:not(.button):not(.dropdown) {
                      margin-right: 0px;
                      margin-left: 6px;
                      color: $color-gray-1;
                    }
                  }
                }
              }
            }
          }

          &.no-word-break {
            white-space: nowrap;
          }

          &.word-break {
            word-break: break-all;
          }

          &.travelers-email-content {
            max-width: 200px;

            .email-content {
              display: block;
              font-size: 12px;
            }
          }


          .dates-content {
            @extend %flex-align-flex-start;

            .time {
              display: block;
              font-size: 12px;
              line-height: 1;
              color: $color-gray-6;
            }
          }
        }

        &:nth-child(2n) {
          td {
            background-color: $color-gray-2;
          }
        }
      }
    }
  }
}