@import 'src/styles/utils';

.users-table {
  .col-email {
    width: 20%;
  }
  .col-first-name {
    width: 19%;
  }
  .col-last-name {
    width: 20%;
  }
  .col-active {
    width: 18%;
  }
  .col-role {
    width: 18%;
  }
  .col-actions {
    width: 5%;
  }

  @include media('desktop-small') {
    .col-active {
      width: 100px;
    }
    .col-role {
      width: 90px;
    }
    .col-actions {
      width: 40px;
    }
  }
}
