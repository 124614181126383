@import 'src/styles/utils';

.create-consumer-api-title {
  color: $color-gray-12;
  margin-bottom: 8px;
  @extend %heading-4-bold;

  .circle.icon {
    margin-left: 6px;
  }
}

.create-consumer-api-content {
  > div {
    padding-bottom: 4px;
  }
}
