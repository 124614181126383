@import 'src/styles/utils';

.named-label {
  .named-label-title {
    @extend %body-sm-bold;
    color: $color-gray-12;
    opacity: 0.8;
  }

  .named-label-value {
    margin-top: 12px;
    opacity: 0.8;
    @extend %body-sm;
    @extend %flex-align-center;

    .named-label-value-icon {
      opacity: 1;
      color: $color-gray-8;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}
