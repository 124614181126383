@import 'src/styles/utils';

.customer-numbers-form-container.ui.segment {
  padding: 16px 24px;
  .page-form {
    .page-form-body {
      padding-top: 18px;
    }
  }
}
