@import 'src/styles/utils';

.inclusive-logic-switch-container {
  margin: 0 -4px;

  .ui.circular.label {
    @extend %action-sm;
    padding: 4px 8px !important;
    margin: 0 4px;
    color: $color-blue-4;
    background-color: $color-gray-1;
    border: 1px solid $color-gray-5;
    cursor: pointer;

    &.readonly {
      cursor: default;
    }

    &.active {
      cursor: default;
      background-color: $color-blue-4;
      color: $color-gray-1;
      border: none;
    }
  }
}
