@import 'src/styles/utils';

.page-title-container {
  input {
    width: 300px;
    height: 32px;
  }
}
.page-form-container {
  .page-form {
    @extend %flex-column;

    .page-form-body {
      .page-form-body-row {
        margin-top: 16px;
      }
    }
  }

  .page-form-footer {
    margin-top: 20px;

    @extend %flex-justify-end;

    .button:first-child {
      margin-right: 10px;
    }
  }
}

.consumers-content-container,
.users-content-container,
.accounts-content-container {
  h3 {
    margin-bottom: 16px;
  }
  .page-form-container {
    padding: 16px 24px;
  }
  div.divider:not(.text) {
    margin: 16px 0;
  }
}

.consumer-info-container {
  .consumer-column > div {
    padding: 10px;
  }
}

.page-form-dialog {
  h3 {
    @extend %heading-3-bold;
  }

  .ui.form-button {
    @extend %action-sm;
  }
}

table tr.selectable {
  cursor: pointer;
}
