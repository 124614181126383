i.icon.account-table-account-row-hint:before {
  margin-left: 10px;
  position: relative;
  top: 1px;
}

.accounts-table {
  td.expander-cell {
    cursor: pointer;
    padding-right: 0px;
  }
}