@import 'src/styles/utils';

.advanced-settings-full-view-container {
  padding: 12px 0;

  .advanced-settings-full-view-container-title {
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
      @extend %heading-3-bold;
      margin: 0;
      white-space: nowrap;
    }
  }

  .advanced-settings-rows-container {
    height: calc(100vh - 190px);
  }

  .advanced-settings-footer-divider {
    margin: 0;
  }

  .advanced-settings-full-view-container-footer {
    padding: 12px 24px 0;
    display: flex;
    justify-content: flex-end;

    .button:first-child {
      margin-right: 12px;
    }
  }
}
