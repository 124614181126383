@import '../../styles/utils';

.boolean-label-container {
  @extend %flex-space-between;
  white-space: nowrap;

  .label {
    padding: 4px 12px;
    overflow: hidden;
    @extend %body-sm;
  }
}

.indicator {
  margin-right: 3px;
  font-size: '.75em' !important;
}

.boolean-label-prime-container {
  @extend %flex-space-between;
  white-space: nowrap;

  .label-tag {
    background-color: $color-gray-3;
    color: $color-gray-8;

    .red {
      color: $color-red-6;
    }

    .blue {
      color: $color-blue-4;
    }

    span {
      line-height: 1.5;
    }
  }
}
