@import '../../../styles/utils';

.no-data-row {
  .text {
    @extend %body-md;
  }
}

.no-data-row-prime {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-gray-6;

  .no-data-icon {
    font-size: 35px;
    font-weight: 900;
  }
}
